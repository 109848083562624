import type { ValidationResult } from "@component-utils/validations"

export type SharedProps = {
  /**
   * Element id
   */
  id?: string
  /**
   * Element name
   */
  name?: string
  /**
   * Label above the component
   */
  label?: string
  /**
   * Tooltip for label above the component
   */
  labelTooltip?: string
  /**
   * Hint under the component
   */
  description?: string
  /**
   * Element is not interactable and is styled appropriate
   */
  disabled?: boolean
  /**
   * Element is not editable
   */
  readonly?: boolean
}

export const SHARED_PROPS_DEFAULTS: SharedProps = {
  id: undefined,
  name: undefined,
  label: undefined,
  labelTooltip: undefined,
  description: undefined,
  disabled: undefined,
  readonly: undefined
}

export type ValidationProps<T> = {
  /**
   * Validation method
   * @param value Value to be validated
   * @returns Result
   */
  validator?: (value: undefined | null | T) => ValidationResult
  /**
   * Defines how `validator` interacts with default component validations
   * 
   * - `merge` - Merge `validator` with default component validations
   * - `force` - Discard default component validations
   */
  validatorMode?: 'merge' | 'force'
  /**
   * When to run validations
   */
  validationTrigger?: 'input' | 'immediate'
}

export const VALIDATION_PROPS_DEFAULTS: ValidationProps<unknown> = {
  validator: undefined,
  validatorMode: 'merge',
  validationTrigger: undefined
}

type AutocompleteAttributeValues = 'off' | 'on' | 'email' | 'name' | 'username' | 'new-password' | 'current-password' | 'one-time-code'

export type Autocompletable = {
  autocomplete?: AutocompleteAttributeValues | Omit<string, AutocompleteAttributeValues>
}